<template>
  <div>
    <Navigation />
    <div class="pa-5">
      <ActivityFilters />
    </div>
    <!-- table -->
    <ResourceTable :query="query">
      <ElTableColumn prop="data.description" label="Descriere" width="300" />
      <ElTableColumn label="Lucrare">
        <ResourceLoader
          slot-scope="{ row }"
          :path="`projects/${row.data.projectId}`"
        >
          <ElButton
            slot-scope="{ resource: project }"
            @click="$set(filters, 'project', project.id)"
            size="mini"
            >{{ project.data.name }}</ElButton
          >
        </ResourceLoader>
      </ElTableColumn>
      <ElTableColumn label="Angajat">
        <template slot-scope="{ row }" v-if="employees">
          <ElButton
            v-for="employeeId in row.data.employeeIds"
            :key="employeeId"
            @click="$set(filters, 'employee', employeeId)"
            size="mini"
            >{{ employeesById[employeeId].data.name }}</ElButton
          >
        </template>
      </ElTableColumn>
      <ElTableColumn prop="data.createdAt" label="Data">
        <TimeAgo
          type="showDate"
          slot-scope="{ row }"
          v-if="row.data.createdAt"
          :date="row.data.createdAt.toDate()"
        />
      </ElTableColumn>

      <ElTableColumn label="Rezultat">
        <template slot-scope="{ row }">
          {{ row.data.result }}
        </template>
      </ElTableColumn>
      <!-- <ElTableColumn align="right" width="110">
        <ElButton
          slot-scope="{ row }"
          @click="$store.removeActivity(row.id)"
          size="mini"
          >Sterge</ElButton
        >
      </ElTableColumn> -->
    </ResourceTable>
    <!-- load more -->
    <div class="pa-5">
      <ElButton icon="el-icon-more" @click="loadMore">Incarca mai multe</ElButton>
    </div>
  </div>
</template>

<script>
import { keyBy } from "lodash";
import FiltersMixin from "@/mixins/FiltersMixin";
import LoadMoreMixin from "@/mixins/LoadMoreMixin";
import ActivityFilters from "@/components/activities/ActivityFilters";

export default {
  mixins: [FiltersMixin(), LoadMoreMixin({ limit: 50 })],
  components: {
    ActivityFilters
  },
  data() {
    return {
      employees: null
    };
  },
  computed: {
    query() {
      let query = this.$firestore()
        .collection("activities")
        .limit(this.limit)
        .orderBy("createdAt", "desc");

      if (this.filters.project) {
        query = query.where("projectId", "==", this.filters.project);
      }

      if (this.filters.employee) {
        query = query.where(
          "employeeIds",
          "array-contains",
          this.filters.employee
        );
      }

      if (this.filters.from && this.filters.to) {
        query = query.where("createdAt", ">=", new Date(this.filters.from));
        query = query.where("createdAt", "<=", new Date(this.filters.to));
      }

      return query;
    },
    employeesById() {
      return keyBy(this.employees, "id");
    }
  },
  created() {
    this.$bind("employees", this.$firestore().collection("employees"));
  }
};
</script>
