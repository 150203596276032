var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navigation'),_c('div',{staticClass:"pa-5"},[_c('ActivityFilters')],1),_c('ResourceTable',{attrs:{"query":_vm.query}},[_c('ElTableColumn',{attrs:{"prop":"data.description","label":"Descriere","width":"300"}}),_c('ElTableColumn',{attrs:{"label":"Lucrare"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('ResourceLoader',{attrs:{"path":("projects/" + (row.data.projectId))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var project = ref.resource;
return _c('ElButton',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.$set(_vm.filters, 'project', project.id)}}},[_vm._v(_vm._s(project.data.name))])}}])})}}])}),_c('ElTableColumn',{attrs:{"label":"Angajat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (_vm.employees)?_vm._l((row.data.employeeIds),function(employeeId){return _c('ElButton',{key:employeeId,attrs:{"size":"mini"},on:{"click":function($event){return _vm.$set(_vm.filters, 'employee', employeeId)}}},[_vm._v(_vm._s(_vm.employeesById[employeeId].data.name))])}):undefined}}],null,true)}),_c('ElTableColumn',{attrs:{"prop":"data.createdAt","label":"Data"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.data.createdAt)?_c('TimeAgo',{attrs:{"type":"showDate","date":row.data.createdAt.toDate()}}):_vm._e()}}],null,true)}),_c('ElTableColumn',{attrs:{"label":"Rezultat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.data.result)+" ")]}}])})],1),_c('div',{staticClass:"pa-5"},[_c('ElButton',{attrs:{"icon":"el-icon-more"},on:{"click":_vm.loadMore}},[_vm._v("Incarca mai multe")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }