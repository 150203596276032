export default ({ limit = 10 }) => ({
  data() {
    return {
      limit
    };
  },
  methods: {
    loadMore() {
      this.limit += limit;
    }
  }
});
