<template>
  <div class="filters">
    <ResourceSelect
      class="mr-1"
      v-model="$parent.filters.project"
      collection-name="projects"
      placeholder="Toate Lucrarile"
    />
    <ResourceSelect
      class="mr-1"
      v-model="$parent.filters.employee"
      collection-name="employees"
      placeholder="Toti Angajatii"
    />
    <ElDatePicker
      class="mr-1"
      :value="dateRange"
      @input="setDateRange"
      type="daterange"
      start-placeholder="Data Initiala"
      end-placeholder="Data Finala"
      value-format="yyyy-MM-dd"
    />
    <ElButton
      circle
      type="primary"
      icon="el-icon-close"
      @click="$parent.resetFilters"
      v-if="$parent.hasActiveFilters"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value6: null
    };
  },
  computed: {
    dateRange() {
      const { from, to } = this.$parent.filters;
      return from && to ? [from, to] : undefined;
    }
  },
  methods: {
    setDateRange(range) {
      this.$set(this.$parent.filters, "from", range[0]);
      this.$set(this.$parent.filters, "to", range[1]);
    }
  }
};
</script>
